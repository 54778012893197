@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF_Pro_Regular";
  src: url("./assets/fonts/SF-Pro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
